/**
 * Style for Site Branding
 *
 * @author Space-Time Inc.
 */

@use '../../../_inc/stile/sass/utility/_utility';
@use '../../../_inc/stile/sass/font/_font';
@use '../variables' as *;
@use '../mixins' as *;

.part-site-branding {
	color          : $color-text;
	font-weight    : normal;
	text-decoration: none;

	a {
		display: flex;
		gap    : bv(0.5rem, 1rem, 900px);
	}

	// .site-description {
	// 	@include font.font-size-line-height(2);

	// 	span { display: inline-block; }
	// }
}

.site-header .part-site-branding {
	display    : flex;
	align-items: center;
	flex-wrap  : wrap;
	gap        : 0.5rem 1rem;

	// min-height: bv(3rem, 4rem, 600px);

	// .site-logo {
	// 	width: bv(3rem, 4rem, 900px);
	// }
	.site-title {
		display  : inline-block;
		width    : 100%;
		max-width: 7rem;
		min-width: 7rem;
	}
}

.site-footer .part-site-branding {
	display    : flex;
	align-items: center;
	justify-content: center;
	flex-wrap  : wrap;
	gap        : 0.5rem 1rem;

	// min-height: bv(3rem, 4rem, 600px);

	.site-logo {
		width: bv(3rem, 4rem, 900px);
	}
	.site-title {
		display  : inline-block;
		width    : 100%;
		max-width: 7rem;
	}
	.site-description {
		width: bp(33%, calc(100% - 3.5rem), 600px, false);
	}
}
