/**
 * Heading Style
 *
 * @author Space-Time Inc.
 */

@use '../../_inc/stile/sass/utility/_utility';
@use '../../_inc/stile/sass/font/_font';
@use 'variables' as *;

h1, h2, h3, h4, h5, h6 {
	color      : $color-heading;
	font-weight: bold;
	@include font.proportional;
	font-family: "Barlow Condensed", sans-serif;

	small { font-weight: normal; }
}

h3 {
	clear: both;
}


// -----------------------------------------------------------------------------


@mixin heading-content {
	h3 { @include heading-third; }
	h4 { @include heading-fourth; }
	h5 { @include heading-fifth; }
	h6 { @include heading-sixth; }
	h3, h4, h5, h6 {
		@include utility.for-ph-o {
			&:not([class]) span {
				display: inline;
			}
		}
	}
}


// -----------------------------------------------------------------------------


@mixin heading-second-custom {
	// Write here your custom styles
}

@mixin heading-third-custom {
	// Write here your custom styles
}

@mixin heading-fourth-custom {
	// Write here your custom styles
}

@mixin heading-fifth-custom {
	// Write here your custom styles
}

@mixin heading-sixth-custom {
	// Write here your custom styles
}


// -----------------------------------------------------------------------------


@mixin heading-second {
	@include font.font-size-line-height(10);
	// text-align: center;
	div:nth-of-type(n+2) {
		margin-block-start: 0.5rem;
		@include font.font-size-line-height(7);
	}

	&[data-length~='long'] {
		@include font.font-size-line-height(8);
		letter-spacing: -0.01rem;

		div:nth-of-type(n+2) {
			@include font.font-size-line-height(6);
		}
	}

	@include utility.for-tl { text-align: center; }

	display        : flex;
	justify-content: center;
	flex-direction : column;

	@include heading-second-custom;
}

@mixin heading-third {
	@include font.font-size-line-height(10);
	color: #36723A;

	small {
		&:first-child { padding-block-start: 0.25rem; }
		&:last-child  { padding-block-end  : 0.25rem; }

		@include font.font-size-line-height(6);
	}

	@include heading-third-custom;
}

@mixin heading-fourth {
	@include font.font-size-line-height(7);

	@include heading-fourth-custom;
}

@mixin heading-fifth {
	@include font.font-size-line-height(6);

	@include heading-fifth-custom;
}

@mixin heading-sixth {
	@include font.font-size-line-height(5);

	@include heading-sixth-custom;
}
