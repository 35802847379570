/**
 * Style for Entries
 *
 * @author Space-Time Inc.
 */

@use '../../_inc/stile/sass/utility/_utility';
@use '../../_inc/stile/sass/font/_font';
@use 'variables' as *;
@use 'mixins' as *;
@use 'heading' as *;

.entry {
	display       : flex;
	flex-direction: column;
	max-width     : 100%;

	padding-block: var(--large-gap);
	gap          : var(--medium-large-gap);

	padding-inline: max(4vw, calc((100% - #{$size-width-text-front}) / 2));
	&.wide {
		padding-inline: max(4vw, calc((100% - #{$size-width-wide}) / 2));
	}

}

.page .entry {
	margin-top: -4rem;
	padding-block: 0 var(--large-gap);
	.user-content .column-three {
		display: flex;
		justify-content: flex-start;
		flex-direction: column; 
		@include utility.for-de { flex-direction: row; }
		gap: 1rem 3rem;
		.column+.column {
			margin-top: 0;
		}
	}
}
.frame-front {
	background-color: #F0664B;
	display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
	padding-block: 3rem;
	box-shadow: rgb(179, 179, 179) 6px 6px 13px 0px;
	padding-inline: 10vw;
	> p {
		text-align: center;
		color: #fff;
		@include font.font-size-line-height(6);
	}
	span {
		display: inline-block;
	}
	+.column {
		margin-top: 3rem;
		
		display: flex;
		gap: 1rem 2rem;
		flex-direction: column;
		@include utility.for-tl { 
			flex-direction: row;
			padding-inline: max(4vw, calc((100% - #{$size-width-text}) / 2));
		}
		.inner h3.front-heading {
			font-family: "Barlow Condensed", sans-serif;
			font-weight: 700;
			@include font.font-size-line-height(11);
			text-align: left;
			color: #2B63AD;
			margin-bottom: 1.5rem;
		}
		.inner .blue {
			color: #3A6BB9;
		}
		.inner:first-child {
			width: 100%;
			@include utility.for-tl { width: 70%; }
		}
		.inner:last-child {
			p { text-align: center; }
		}
	}
}
.front-column {
	margin-top: 3rem;
		
	display: flex;
	gap: 1rem 2rem;
	flex-direction: column;
	@include utility.for-tl { 
		flex-direction: row;
		padding-inline: max(4vw, calc((100% - #{$size-width-text}) / 2));
	}
	.inner h3.front-heading {
		font-family: "Barlow Condensed", sans-serif;
		font-weight: 700;
		@include font.font-size-line-height(11);
		text-align: left;
		color: #2B63AD;
		margin-bottom: 1.5rem;
	}
	.inner .blue {
		color: #3A6BB9;
	}
	.inner:first-child {
		width: 100%;
		@include utility.for-tl { width: 70%; }
	}
	.inner:last-child {
		p { text-align: center; }
	}
}
.fb-container {
	background-color: #F5F8FA;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	@include utility.for-ta { align-items: flex-start;}
	padding-block: 0;
	// box-shadow: rgb(179, 179, 179) 6px 6px 13px 0px;
	// padding-inline: 10vw;
	// > p {
	// 	text-align: center;
	// 	color: #fff;
	// 	@include font.font-size-line-height(6);
}


h2.front-heading {
	font-family: "Barlow Condensed", sans-serif;
	font-weight: 600;
	font-style: normal;
	font-size: 3.75rem;
	text-align: center;
	color: #2B63AD;
}

.entry.theme {
	background-image: url('../images/wave.png');
	background-repeat: repeat;
	color: #fff;
	padding-inline: max(4vw, calc((100% - #{$size-width-text}) / 2));
	.front-heading { 
		color: #fff;
		line-height: 3rem;
		text-align: center;
		> sup {font-size: 1.3rem;}
	}

}
.entry.news {
	background-color: #F5F8FA;
	.list-item-news {
		display: flex;
		flex-direction: column;
		
		gap: 1rem;
		li > a,
		li > div{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			// @include utility.for-pl { 
				
				
			// }
			background-color: $color-bg;
			position: relative;
		}
		li>div::before,
		li>a::before, 
		li>div::after,
		li>a::after {
			content: '';
			display: block;
			position: absolute;
			border-width: 10px;
			border-style: solid;
		}
		li>div::before,
		li>a::before {
			border-top-color: #F2CE09;
			border-left-color:  #F2CE09;
			border-right-color: transparent;
			border-bottom-color: transparent;
		}
		li>div::after,
		li>a::after {
			border-top-color: transparent;
			border-left-color: transparent;
			border-right-color: #F2CE09;
			border-bottom-color: #F2CE09;
			right: 0;
			bottom: 0;
		}
		.content {
			padding: 1rem;
			flex-grow: 1;
		}
		.thumbnail {
			align-self: flex-end;
			padding-bottom: 0;
			// padding-bottom: 1rem;

			// @include utility.for-pl { padding-bottom: 0;}
		}
		.thumbnail img {
			object-fit: cover;
			aspect-ratio: 1;
			max-width: 5rem;
			@include utility.for-ta { 
				min-width: 8rem;
				max-width: 10rem;
				height: auto;
				aspect-ratio: auto;
				object-fit: contain;
			}
		}
		p,div { margin: 0;}
	}
}
.section-sp {
	.platinum-container {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		@include utility.for-ta{
			grid-template-columns: repeat(3, 1fr);
		}
		gap: 1.5rem;
	}
	.gold-container {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1.5rem;
	}
}
.entry.venue {
	iframe { 
		width: 100%;
		min-height: 15rem;
		max-height: 15rem;
	}
	.user-content div.nth-of-type {
		margin-block-end: 2rem;
	}
}
.section-committee {
	.list-item-committee {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		@include utility.for-ta{
			grid-template-columns: repeat(3, 1fr);
		}
		gap: 1.5rem;
	}
}
// -----------------------------------------------------------------------------


.entry-header {
	position: relative;

	.title {
		@include heading-second;

		span:last-child { display: inline-block; }
		@include utility.for-ta {
			span { display: inline-block; }
		}
		> div { max-width: 100%; }
	}

	.type-title {
		position: absolute;
		top     : -1.5rem;
		left    : 0;
		height  : 1.5rem;

		display    : grid;
		place-items: center;
		line-height: 1;

		padding         : 0 0.5rem;
		background-color: $color-base;
		color           : $color-text-inv;
	}

	.info {
		display    : flex;
		align-items: flex-start;
		gap        : 1rem;

		margin-top: 0.75rem;

		@include font.font-size-line-height(3);
	}

	.date {
		display: inline-block;

		color      : $color-text-sub;
		white-space: nowrap;
	}

	.list-cat {
		display  : flex;
		flex-wrap: wrap;
		gap      : 0.25rem 0.5rem;

		li a {
			display: inline-flex;

			padding         : 0 0.5rem;
			background-color: $color-accent;
			color           : $color-text-inv;
			text-decoration : none;
		}
	}
}

.entry-content {
	flex-grow: 1;
}

.entry-thumbnail {
	.thumbnail {
		object-fit: cover;
		width     : 100%;
		height    : auto;
		min-height: 15rem;
		max-height: 25rem;
	}
	.entry-header {
		position: relative;
		top: -33%;
		color: $color-text-inv;
		width: 100%;
		.title {
			font-size: 4rem;
			color: $color-text-inv;
			text-align: left;
			padding-inline: max(4vw,(100% - 72rem) / 2);
			font-family: "Barlow Condensed";
			font-weight: bold;
			line-height: 1.5;
			// background-color: #0000008c;
			text-shadow: 2px 3px 5px #1a1a1a;
		}
	}
}

.entry-content.schedule {
	--nc-tab-scroll-bar-background: transparent;
	--nc-tab-scroll-bar-border-block-end: 1px solid #707070;
	--nc-tab-scroll-tab-current-color:  #F0664B;
	--nc-tab-scroll-tab-current-border-block-end: 1px solid #F0664B;
	--nc-tab-scroll-tab-current-border-block-start: none;
	--nc-tab-scroll-tab-current-border-inline: none;
	--nc-tab-scroll-tab-padding: 1rem 0.5rem;

	--nc-tab-scroll-tab-hover-background: #{rgba(#A6C348, 0.25)};

	ul[data-nc-tab-bar]>li {
		font-size: 24px;
		a { border-radius: 1rem 1rem 0 0; }
	}

	.tab-scroll {
		h3 { display: none; }
		h4 {
			@include font.font-size-line-height(11);
			font-family: "Barlow Condensed";
			font-weight: bold;
			color: #2B63AD;
		}

		.time {
			display: inline-block;
			padding: 0.5rem;
			border-radius: 5px;
			min-width: 10rem;
			text-align: center;
			font-weight: bold;
		}
		.item-schedule {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			gap: 0rem 1.5rem;
			@include utility.for-ph-o { flex-direction: column; }
			@include utility.for-tl { 
				flex-wrap: nowrap;
			}
			>p { margin: 0; }
			&:not(:has(.info .time)) {
				@include utility.for-ta {
					margin-top: 0.5rem;
					padding-left: 11.5rem; 
				}
			}
		}
		.item-schedule + .item-schedule,
		.more + .item-schedule { 
			margin-top: 1.25rem;
			@include utility.for-ta { margin-top: 2.5rem; }
		}

		.item-schedule.with-more {
			position: relative;
			cursor: pointer;
			// pointer-events: painted outside;
			&:hover {
				&::after{ animation: fuyo 3s ease infinite; }
			}
			&::after {
				position: absolute;
				right: 0;
				top: 0.5rem;
				content: '';
				width: 1.5rem;
				height: 1.5rem;
				clip-path: polygon(34% 31%, 53% 50%, 34% 69%, 41% 76%, 67% 50%, 41% 24%);
				transform: rotate(90deg);
				box-shadow: 0 0 0 1rem #3A6BB9 inset;
			}
			&.opened::after {
				transform: rotate(-90deg);
				animation: none;
			}

		}
		.info {
			display: flex;
			gap: 0.5rem 1.5rem;
			align-items: flex-start;
			flex-direction: column;
			@include utility.for-ta { 
				flex-direction: row; 
				align-items: center;
			}
		}
		.time.yellow { background-color: #DBB900; }
		.time.green  { background-color: #36723A; }
		.time.blue   { background-color: #3A6BB9; }
		.time.red    { background-color: #F0664B; }
		.time.green,
		.time.blue,
		.time.red { color: $color-text-inv; }

		.place {
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;

			min-width: 6rem;
			padding-block: .25rem;
			padding-inline: 0.5rem;
			border-radius: 2rem;
			// width: 6rem;
			// text-align: center;

			&::before {
				// position: absolute;
				// left: 1rem;
				// top: 50%;
				// transform: translateY(-50%);

				content: '';
				width: 1rem;
				height: 1rem;
				background-image: url('../images/icon-pin.svg');
			}
		}
		.place.hall { background-color: rgba(#DBB900, 0.25); }
		.place.a  { background-color: rgba(#36723A, 0.25); }
		.place.b   { background-color: rgba(#A6C348, 0.25); }
		.place.hotel    { background-color: rgba(#C7D6ED, 1); }

		.content {
			@include font.font-size-line-height(6);
			@include utility.for-ta { padding-inline-end: 2rem; }
		}
		.more {
			display: none;
			@include utility.for-tl { padding-left: 12rem; }
			// margin-top: 10px;
			// padding: 5px;
			// background-color: #f0f0f0;
			// border: 1px solid #ccc;
			// border-radius: 5px;
			.name,
			.country,
			.block {
				margin: 0;
			}
		}
		.more .column{
			display: flex;
			align-items: flex-start;
			gap: 1rem;

		}
	}
}

@keyframes fuyo {
	0% {
		transform: translateY(0) rotate(90deg);
	}
	10% {
		transform: translateY(-10px) rotate(90deg);
	}
	20% {
		transform: translateY(0) rotate(90deg);
	}
	30% {
		transform: translateY(-5px) rotate(90deg);
	}
	40% {
		transform: translateY(0) rotate(90deg);
	}
	100% {
		transform: translateY(0) rotate(90deg);
	}
}