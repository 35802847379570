/**
 * Site Footer
 *
 * @author Space-Time Inc.
 */

@use 'sass:math';
@use '../../_inc/stile/sass/utility/_utility';
@use '../../_inc/stile/sass/font/_font';
@use 'variables' as *;
@use 'mixins' as *;

.site-footer {
	display       : flex;
	flex-direction: column;

	.main,
	.sub,
	.misc {
		display       : flex;
		padding-inline: max(2vw, calc((100% - #{$size-width}) / 2));
	}

	.main {
		border-top: solid 1px #d0d0d0;
		flex-direction: column;
		@include utility.for-ta { flex-direction : row; }
		// flex-wrap      : wrap;
		justify-content: space-between;
		gap            : 1rem;

		padding-block: 2rem 3rem;
		//background   : linear-gradient(to bottom right, lighten($color-bg-1, 5%) 0%, $color-bg-1 100%);
		background: #FFF;
		padding-inline: max(4vw,(100% - 64rem) / 2);
	}

	.sub {
		flex-direction: column;

		background: linear-gradient(to bottom right, lighten($color-bg-2, 5%) 0%, $color-bg-2 100%);
	}

	.misc {
		justify-content: flex-end;
	}

	// .footer-nav {
	// 	// flex-grow: 2;
	// 	// max-width: 100%;
	// 	max-width: 45%;
	// }
	.site-branding {
		min-width: 10rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		gap: 1rem;
	}
	.copy-right {
		text-align: center;
		padding-block: 1rem;
		color: $color-base;
		background-color: #E7ECF4;
	}
}


//------------------------------------------------------------------------------


.footer-nav {
	display        : flex;
	align-items    : flex-start;
	justify-content: flex-end;
	@include utility.for-ta { max-width: 45%; }
}

.footer-nav .menu {
	column-count: 2;
    @include utility.for-ph-o {
	width: 100%;
    text-align: center;
	}
	li {
		display: inline-block;
		width: 100%;
	}
	li > a,
	li > label {
		min-height: 1.5rem;
		padding   : 0.5rem;
		color     : $color-base;
		// @include font.font-size-line-height(3);
		width: unset;
	}
	li > label { 
		pointer-events: none;
		color: #979797;;
	}
	ul.child {
		padding-left: 1rem;
	}
}


//------------------------------------------------------------------------------


#back-to-top {
	display    : grid;
	place-items: center;

	position: fixed;
	z-index : 9999;
	width   : 3rem;
	height  : 3rem;

	bottom: 0;

	&:after {
		content : '';
		position: relative;
		top     : 0.5rem;

		width : 1.75rem;
		height: 1.75rem;

		border-top : 4px solid #f0664ba8;
		border-left: 4px solid #f0664ba8;

		transform-origin: #{math.div(1.75rem, 2)} #{math.div(1.75rem, 2)};
		transform       : rotate(45deg);
	}

	transition: all 0.2s;
	&:hover {
		opacity: 1;
	}
	&.hidden {
		opacity       : 0;
		pointer-events: none;
	}
}
