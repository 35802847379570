/**
 * Style for Banner Area and Widgets
 *
 * @author Space-Time Inc.
 */

@use '../../../_inc/stile/sass/utility/_utility';
@use '../../../_inc/stile/sass/font/_font';
@use '../variables' as *;
@use '../mixins' as *;

.part-banner-area {
	padding-block: 2rem;
}

.list-widget {
	display        : flex;
	align-items    : flex-start;
	justify-content: center;
	flex-wrap      : wrap;
	gap            : 1.5rem;

	.widget {
		max-width: 16rem;
	}
}


//------------------------------------------------------------------------------


.widget {
	a:hover {
		opacity: 0.5;
	}

	img {
		width       : 100%;
		aspect-ratio: 11 / 3;
		object-fit  : cover;
	}
}
