/**
 * Theme Style
 *
 * @author Space-Time Inc.
 */

@charset 'utf-8';

@use '../../_inc/stile/sass/utility/_utility';
@use '../../_inc/stile/sass/font/_font';

@use 'variables' as *;
@use 'mixins' as *;
@use 'content-mixins' as *;

@use 'part/site-branding';
@use 'part/global-nav';
@use 'part/top-visual';
@use 'part/banner-area';

@use 'heading';
@use 'form';
@use 'header';
@use 'entry';
@use 'footer';
@use 'print';

@font-face {
	font-family: 'SourceHanSansJP-VF';
	font-weight: 100 900;
	src        : url('../fonts/SourceHanSansJP-VF.ttf.woff2') format('woff2-variations');
}

/* For chrome */
@supports (-webkit-appearance: none) and (not (-moz-appearance: none)) and (not (font: -apple-system-body)) {
	img {
		image-rendering: -webkit-optimize-contrast;
	}
}

// *, *::before, *::after { outline: 1px solid rgba(red, 0.5); }


// -----------------------------------------------------------------------------


:root {
	--nc-font-factor: 0.65;
	@include utility.for-tp { --nc-font-factor: 0.85; }
	@include utility.for-tl { --nc-font-factor: 1.00; }

	--nc-line-interval       : 0.9;
	--nc-link-direction-color: #{$color-base};
	--small-gap       : 0.75rem;
	--medium-gap      : 1.5rem;
	--medium-large-gap: 1.5rem;
	--large-gap       : 3rem;
	--ui-size         : 2.5rem;

	@include utility.for-ta {
		--small-gap       : 1.5rem;
		--medium-gap      : 3rem;
		--medium-large-gap: 3rem;
		--ui-size         : 3rem;
	}
	@include utility.for-de {
		--medium-large-gap: 4.5rem;
		--large-gap       : 4.5rem;
	}
}

body .site> img {
	width: 100%;
	min-width: 10rem;
}

html, body {
	scroll-padding-top: 3rem;

	display      : grid;
	grid-template: 1fr / 100%;
}

body {
	font-family: SourceHanSansJP-VF, var(--nc-font-sans);
	font-size  : 16px;

	color           : $color-text;
	background-color: $color-bg;

	max-width : 100%;
	overflow-x: hidden;
}

.site {
	display      : grid;
	grid-template: auto 1fr auto / 100%;

	:is(.site-content, .content-area, .site-main) {
		width   : 100%;
		overflow: hidden;
	}
	:is(.site-content, .content-area) {
		display      : grid;
		grid-template: 1fr / 100%;
	}
	.site-main {
		display       : flex;
		flex-direction: column;

		.entry {
			flex-grow: 1;
		}
	}
}

.site-header,
.site-footer {
	@include font.proportional;
}

.site-content {
	background-color: #F5F8FA;

	position: relative;
	z-index : 0;
	// &::before {
	// 	content: '';

	// 	position : absolute;
	// 	z-index  : -1;
	// 	height   : 100%;
	// 	left     : 50%;
	// 	transform: translateX(-50%);

	// 	background-color: $color-bg;
	// 	width           : bv($size-width, 100%, $size-width + 4rem, false);

	// 	box-shadow: 0 0 4px rgb($color-text, 0.1);
	// }
}

body.page,
body.page-schedule{
	.site-content { background-color: #fff; }
}
.user-content {
	h3.red {
		color: #F0664B;
	}
	table {
		width: 100%;
	}
	tr.head {
		background-color: #36723A;
		color: #fff;
		
	}
	tr.time-slot {
		background-color: #f5f8fa;
	}
	tr.time-poster {
		background-color: #fdefed;
	}
	td.middle {
		vertical-align: middle;
	}
	tr {
		text-align: center;
	}
	td.sp-tl {
		@include utility.for-ph-o {
			text-align: left;
		}
	}
	.text-center {
		text-align: center;
		@include font.font-size-line-height(5);
	}
	.text-left {
		text-align: left;
	}
	tr strong {
		@include utility.for-tp-o{
			font-size: 1.25rem;
			line-height: 1.5rem;
		}
		@include utility.for-tl {
			font-size: 1.5rem;
			line-height: 1.75rem;
		}

	}
	tr.odd,
	tr.even {
		td:nth-child(2) {
			text-align: left
		}
	}
	tr.even {
		background-color: rgba(233, 233, 233, 0.7);
	}
	.button-wrap {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 1rem 2rem;

		margin-top: 3rem;
	}
	.button-wrap p {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 1rem 2rem;
	}
	.item-speaker + .item-speaker {
		margin-top: 4rem;
	}
	.item-speaker {
		h3 { margin-bottom: 0; }
		h3 + p { 
			margin-top: 0;
			color: #8F8F8F;
			font-size: 1.25rem;
			font-weight: bold;
		}
	}
	img.face-photo {
		// width: 14rem;
		width: clamp(12rem, 50vw, 15rem);
		@include utility.for-ta {
			float: left;
			margin-bottom: 2rem;
		}
		margin-right: 3rem;
	}
	.gallery-style ul:not([class]){
		padding-left: 0;
		list-style: none;
	
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		@include utility.for-ph-o { grid-template-columns: repeat(2, 1fr); }
		@include utility.for-tp-o { grid-template-columns: repeat(3, 1fr); }
		@include utility.for-tl { grid-template-columns: repeat(5, 1fr); }
		gap: 1rem;
		li { 
			margin: 0; 
			img {
				aspect-ratio: 1/1;
				object-fit: cover;
			}
		}
		li::marker {
			content: none;
		}
	}
	.award-photo {
		img { 
			display: block;
			width: min(100%, 20rem);
			margin-inline: auto;
			// padding-inline: 0;
			// margin-bottom: 1rem;
			// @include utility.for-ta {
			// padding-inline: 10rem;
			// margin-bottom: 3rem;
			// }
		}
	}
}
// -----------------------------------------------------------------------------


:where(
	.list-item,
	.menu
) a {
	text-decoration: inherit;
	color          : inherit;
}

.menu :is(a, label) {
	display        : inline-flex;
	align-items    : center;
	justify-content: center;

	width : 100%;
	height: 100%;

	white-space: nowrap;
	line-height: 1;
	user-select: none;

	color: $color-text-sub;

	:is(.header-nav, .footer-nav) &:hover {
		text-decoration: underline;
	}
}

.button-row {
	margin-top: 2rem;

	display        : flex;
	flex-wrap      : wrap;
	justify-content: center;
	gap            : 0.25rem;

	position: relative;
	z-index : 1;

	&.left  { justify-content: flex-start; }
	&.right { justify-content: flex-end; }

	.button {
		margin   : 0;
		min-width: 12rem;
	}
}

.aside-title {
	padding-bottom: 0.75rem;
	border-bottom : 1px dashed $color-line;
	font-weight   : bold;
	color         : $color-text-sub;

	& + * { margin-block-start: 1.5rem; }
}


// -----------------------------------------------------------------------------


.user-content {
	@include font.font-size-line-height(4);
	@include heading.heading-content;
	@include content-custom;
	@include content-custom-frame;
	@include link-icon-file;
}

@include form.button-content;
