/**
 * Variables
 *
 * @author Space-Time Inc.
 */

$color-base      : #3A6BB9;
$color-base-light: hsl(145, 35%, 60%);
$color-base-dark : hsl(145, 20%, 20%);

$color-accent: hsl(35, 35%, 40%);;
$color-fb:#0866ff;

$color-text    : #111;
$color-text-sub: #777;
$color-text-inv: #fff;
$color-heading : #222;
$color-line    : $color-base;

$color-bg  : #fff;
$color-bg-1: #eee;
$color-bg-2: #ddd;

$size-border-width : 1px;
$size-border-radius: 2rem;
$size-border-cham  : 1.5px;

$size-width     : 72rem;
$size-width-wide: 64rem;
$size-width-text-front: 64rem;
$size-width-text: 48rem;
