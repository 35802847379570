/**
 * Width
 *
 * @author Takuto Yanagida
 * @version 2021-11-25
 */

@mixin for-width-down($high-px, $high-mm) {
	@media screen and (max-width: #{$high-px - 0.5}), print and (max-width: #{base-to-px($high-mm) - 0.5}) {
		@content;
	}
}

@mixin for-width-only($low-px, $high-px, $low-mm, $high-mm) {
	@media screen and (min-width: $low-px) and (max-width: #{$high-px - 0.5}), print and (min-width: base-to-px($low-mm)) and (max-width: #{base-to-px($high-mm) - 0.5}) {
		@content;
	}
}

@mixin for-width-up($low-px, $low-mm) {
	@media screen and (min-width: $low-px), print and (min-width: base-to-px($low-mm)) {
		@content;
	}
}

@mixin for-phone-up              { @content; }
@mixin for-phone-portrait-up     { @content; }
@mixin for-phone-landscape-up    { @include for-width-up( 450px, 105) { @content; } }
@mixin for-tablet-up             { @include for-width-up( 600px, 148) { @content; } }
@mixin for-tablet-portrait-up    { @include for-width-up( 600px, 148) { @content; } }
@mixin for-tablet-landscape-up   { @include for-width-up( 900px, 210) { @content; } }
@mixin for-desktop-up            { @include for-width-up(1200px, 297) { @content; } }
@mixin for-big-desktop-up        { @include for-width-up(1500px, 420) { @content; } }

@mixin for-phone-only            { @include for-width-down(         600px,      148) { @content; } }
@mixin for-phone-portrait-only   { @include for-width-down(         450px,      105) { @content; } }
@mixin for-phone-landscape-only  { @include for-width-only( 450px,  600px, 105, 148) { @content; } }
@mixin for-tablet-only           { @include for-width-only( 600px, 1200px, 148, 297) { @content; } }
@mixin for-tablet-portrait-only  { @include for-width-only( 600px,  900px, 148, 210) { @content; } }
@mixin for-tablet-landscape-only { @include for-width-only( 900px, 1200px, 210, 297) { @content; } }
@mixin for-desktop-only          { @include for-width-only(1200px, 1500px, 297, 420) { @content; } }
@mixin for-big-desktop-only      { @include for-width-up  (1500px,         420)      { @content; } }


// -----------------------------------------------------------------------------


@mixin for-ph   { @include for-phone-up              { @content; } }
@mixin for-pp   { @include for-phone-portrait-up     { @content; } }
@mixin for-pl   { @include for-phone-landscape-up    { @content; } }
@mixin for-ta   { @include for-tablet-up             { @content; } }
@mixin for-tp   { @include for-tablet-portrait-up    { @content; } }
@mixin for-tl   { @include for-tablet-landscape-up   { @content; } }
@mixin for-de   { @include for-desktop-up            { @content; } }
@mixin for-bd   { @include for-big-desktop-up        { @content; } }

@mixin for-ph-o { @include for-phone-only            { @content; } }
@mixin for-pp-o { @include for-phone-portrait-only   { @content; } }
@mixin for-pl-o { @include for-phone-landscape-only  { @content; } }
@mixin for-ta-o { @include for-tablet-only           { @content; } }
@mixin for-tp-o { @include for-tablet-portrait-only  { @content; } }
@mixin for-tl-o { @include for-tablet-landscape-only { @content; } }
@mixin for-de-o { @include for-desktop-only          { @content; } }
@mixin for-bd-o { @include for-big-desktop-only      { @content; } }
