/**
 * Style for Global Navigation
 *
 * @author Space-Time Inc.
 */

@use 'sass:math';
@use '../../_inc/stile/sass/utility/_utility';
@use '../../_inc/stile/sass/font/_font';
@use 'variables' as *;

.site-header .gida-menu-global {
	--bar-mark-color: #{$color-base};
	--bar-item-width  : unset;
	--bar-item-height : 3rem;
	--bar-item-padding: 0.5rem 1rem;

	@include utility.for-tp {
		--bar-item-width  : unset;
	}
	@include utility.for-tl {
		--bar-item-width  : unset;
	}

	--bar-scroll-shadow-start  : #{rgba(#000, 0.50)};
	--bar-scroll-shadow-end    : #{rgba(#000, 0.00)};
	--bar-scroll-scroller-start: #{rgba($color-base, 0.99)};
	--bar-scroll-scroller-end  : #{rgba($color-base, 0.00)};

	--panel-bg-color    : #{$color-bg};
	--panel-shadow      : 0 4px 8px 0 rgba(0, 0, 0, 0.25);
	--panel-item-width  : unset;
	--panel-item-height : 3rem;
	--panel-item-padding: 0.25rem 0.5rem;

	@include utility.for-tp {
		--panel-item-padding: 1rem;
	}
}
.site-header {
	.global-nav .menu{
		width: 100%;
	}
	.gida-menu-global {
		width: 100%;
	}
	.gida-menu-global-bar {
		width: 100%;
		justify-content: flex-end;
	}
}

.gida-menu-global-bar > .menu {
	gap: 0.5rem;
	@include utility.for-tl { gap: 1rem; }
}


// -----------------------------------------------------------------------------


.gida-menu-global-bar .menu > li {
	// width: 8rem;
	flex-grow: unset;
	// Separator
	// &:not(.hover) + li:not(.hover) {
	// 	&::before {
	// 		content    : '';
	// 		display    : block;
	// 		position   : absolute;
	// 		width      : 1px;
	// 		height     : 1rem;
	// 		border-left: 1px solid $color-base-light;
	// 		left       : -0.5px;
	// 		top        : calc(50% - 0.5rem);
	// 	}
	// }

	// ----

	> * {
		color: $color-text-inv;
		font-weight: 600;
	}
	> a::before {
		content: '';
		position: absolute;
		z-index: 1;
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: #7add68;
		transform-origin: 100% 0;
		transform: scaleX(0);
		transition: transform .3s cubic-bezier(0.13, 0.78, 0.38, 0.98);
	}
	// Static state
	&.current,
	&.menu-ancestor,
	&.page-ancestor {
		> * {
			color           : #36723a;;
			// background-color: $color-bg;
		}
	}
	// Dynamic state
	&.hover,
	&.opened {
		> * {
			color           : #f0664b;
		}
		
		> a::before {
			opacity: 1;
			transform-origin: 0 0;
			transform: scaleX(1);
		}
	}

	// ----

	> label::after {
		// Style for hint of pop-upping
	}
	// Static state
	&.current,
	&.menu-ancestor,
	&.page-ancestor {
		> label::after {
			// Style for hint of pop-upping
		}
	}
	// Dynamic state
	&.hover,
	&.opened {
		> label::after {
			// Style for hint of pop-upping
		}
	}
}


// -----------------------------------------------------------------------------

.gida-menu-global-panel {
	width: 10rem
}
.gida-menu-global-panel > .menu > li {
	a, label {
		@include font.font-size-line-height(3);
		white-space: normal;

		span { display: inline-block; }

		> div {
			display   : inline-block;
			text-align: left;
		}

		.description {
			width     : 100%;
			margin-top: 0.25rem;
			color     : $color-text-sub;
			@include font.font-size-line-height(2);
		}
	}
	// Static state
	&.current,
	&.menu-ancestor,
	&.page-ancestor {
		a, label {
			> div { text-decoration: underline; }
		}
	}
	// Dynamic state
	&.hover {
		a, label {
			> div { text-decoration: underline; }
		}
	}
}



.part-hamburger {
	--bar-mark-path   : polygon(50% 20%, 100% 0, 50% 100%, 0 0);
	position: fixed;
	inset   : var(--wp-admin--admin-bar--height, 0px) 0 0 auto;
	z-index: 300;

	padding-block        : 4rem;
	padding-inline: 5rem;
	background-color: rgba(0,0,0,0.85);
    width: 100%;
    min-width: 20rem;
	max-width: 30rem;
	transform: translateX(100%);
	transition: transform 0.5s;

	display       : flex;
	flex-direction: column;
	align-items: center;
	
	overflow-y: auto;
	overflow-x: hidden;
	&.open {
		transform: translateX(0);
	}

	.title {
		// font-size: 0;

		// background-image: url('../images/img-menu.svg');
		font-weight: 500;
		color: $color-text-inv;
		background-position: center;
		background-size: contain;
		width: 4rem;
		height: 1.5rem;
		margin-bottom: 1.5rem;
		@include font.font-size-line-height(5);
	}
	
	nav {
		width: 100%;
		> .global,
		> .header {
			border-top: solid 1px $color-bg;
		}
	}

	#close-hamburger {
		position: absolute;
		right: 1rem;
		top: 1.5rem;
		z-index: 2;
		width: 2rem;
		height: 2rem;
		background-image: url('../images/icon-close.svg');
		background-size: contain;
		border-radius: 0;
		

		font-size       : 0;
		border          : 0;
		background-color: transparent;

	}
	.menu {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	.menu>li a {
		@include font.font-size-line-height(6);
		color: $color-text-inv;
		&:hover {
			color: #f0664b;
		}
	}
	.menu>li.current a {
		color: #3a6bb9;
		font-weight: 900;
		pointer-events: none;
	}
}