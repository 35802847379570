/**
 * Mixins for Style
 *
 * @author Space-Time Inc.
 */

@use '../../_inc/stile/sass/utility/_utility';
@use '../../_inc/stile/sass/font/_font';
@use '../../_inc/stile/sass/link/_link';
@use 'variables' as *;

@function bp($min, $max, $bp, $positive: true) {
	$m: if($positive == true, 9999, -9999);
	@return clamp(#{$min}, calc((100% - #{$bp}) * #{$m}), #{$max});
}

@function bv($min, $max, $bp, $positive: true) {
	$m: if($positive == true, 9999, -9999);
	@return clamp(#{$min}, calc((100vw - #{$bp}) * #{$m}), #{$max});
}


// -----------------------------------------------------------------------------


@mixin link-icon-file {
	a:not([class])[data-nc-link-file]::after {
		content: '';

		width : 36px;
		height: 18px;

		border-radius: initial;

		background-color: transparent;
		background-size : contain;

		transform: translateY(calc(0.02rem + 3px));
	}
	a:not([class])[data-nc-link-file ~= 'pdf']::after { background-image: url('../images/link/icon-file-pdf.svg'); }
	a:not([class])[data-nc-link-file ~= 'doc']::after { background-image: url('../images/link/icon-file-word.svg'); }
	a:not([class])[data-nc-link-file ~= 'xls']::after { background-image: url('../images/link/icon-file-excel.svg'); }
	a:not([class])[data-nc-link-file ~= 'ppt']::after { background-image: url('../images/link/icon-file-powerpoint.svg'); }
}
