/**
 * Site Header
 *
 * @author Space-Time Inc.
 */

@use '../../_inc/stile/sass/utility/_utility';
@use '../../_inc/stile/sass/font/_font';
@use 'variables' as *;
@use 'mixins' as *;

.site-header {
	display       : flex;
	flex-direction: column;

	position: absolute;
	width: calc(100% - 10vw);
	max-width: 95rem;
	margin: 0 auto;
	z-index: 2;
	top: 1rem;
	left: 0;
	right: 0;

	.main {
		display        : flex;
		flex-direction : row-reverse;
		align-items: center;
		// flex-wrap      : wrap;
		justify-content: space-between;
		gap            : 2rem;

		padding-block : 0.5rem;
		padding-inline: 1rem;
		@include utility.for-ta { 
			gap            : 1rem;
			padding-inline: 1.5rem;
		}
		@include utility.for-tl {
			padding-block : 1rem;
			padding-inline: 2.5rem;
		} 
		background    : $color-bg;
		border-radius: 5rem;
	}

	// .sub {
	// 	padding-inline: max(0vw, calc((100% - #{$size-width}) / 2));
	// 	background    : linear-gradient(to bottom right, lighten($color-base, 5%) 0%, $color-base 100%);
	// }

	.part-global-nav {
		align-items    : flex-start;
		justify-content: flex-end;
		
		max-width: calc(100% - 7rem);
		min-width: 8rem;
		@include utility.for-de { max-width: 48rem; }
		@include utility.for-ph-o {
			display: none;
		}
		@include utility.for-tp-o {
			display: none;
		}
	}

	.site-branding {
		min-width: 8rem;
	}
	.open-hamburger {
		//order: 4;
		
		&:not(.ready) {
			@include utility.for-tl {
				display: none;
			}
		}
		@include utility.for-tl {
			display: none;
		}
		font-size       : 0;
		border          : 0;
		border-radius   : 0;
		background-color: transparent;
		width: 2rem;
		min-width: 2rem;
		// background-image: url('../images/icon-');
		// @include utility.for-pl{
		// 	width: 3.5rem;
		// 	min-width: 3.5rem;
		// }
		aspect-ratio: 1;
		//margin-inline-end: 1.5rem;
		//@include utility.for-ta { margin-inline: 1rem; }
		opacity: 1;
		// @include utility.for-ta { opacity: 1;}
		
		background: {
			position: center;
			image   : url('../images/icon-menu.svg');
			size    : contain;
		}
		&:hover {
			opacity: 0.7;
		}
		&.ready {
			opacity: 0;
			transition: opacity 0.3s;
		}
		&.fixed {
			position: fixed;
			z-index: 100;
			right: 1rem;
			top: 1rem;
			// background: {
			// 	position: center;
			// 	image   : url('../assets/images/icon-menu.svg');
			// 	size    : contain;
			// }
		}
		&.ready.fixed {
			opacity: 1;
		}
	}
}


//------------------------------------------------------------------------------


.part-global-nav {

	align-items    : flex-start;
	justify-content: flex-end;
	
	max-width: calc(100% - 15rem);
	@include utility.for-de { max-width: 48rem; }
}


.part-global-nav .menu {
	//justify-content: flex-end;
	width: 100%;
	margin-right: 0;

	li > * {
		padding-block: 0.25rem;
		padding-inline: 0.35rem;
		color        : $color-base;
		@include font.font-size-line-height(5);
	}
}
