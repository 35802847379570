/**
 * Style of Top Visual of Front Page
 *
 * @author Space-Time Inc.
 */

@use 'sass:math';
@use '../../_inc/stile/sass/utility/_utility';
@use 'variables' as *;

.part-top-visual {
	@include utility.for-ph-o {
		padding-top: 5rem;
		background-color: #6da9c3;
	}
}

.part-top-visual .gida-slider-show {
	--size-width     : 100rem;
	--size-height-min: 14rem;
	--size-height-max: 100vh;
	--color-frame    : #fff6;

	--slide-ratio     : #{math.percentage(math.div(9, 16))};
	--slide-size      : cover;
	--slide-zoom      : 1.02;
	--slide-color-dual: #fff6;

	--caption-color           : #fff;
	--caption-color-shadow    : #000;
	--caption-color-bg        : #0006;
	--caption-size-font-circle: 1.85rem;
	--caption-size-font-line  : 1.85rem;

	--btn-size            : 2rem;
	--btn-size-mark       : 0.75rem;
	--btn-size-radius     : 50% 0 0 50%;
	--btn-color           : transparent;
	--btn-color-hover     : #0006;
	--btn-mark-color      : #{$color-accent};
	--btn-mark-color-hover: #fff;
	--btn-mark-path       : polygon(30% 10%, 30% 90%, 95% 50%);

	--rivet-size-width      : 0.5rem;
	--rivet-size-height     : 0.5rem;
	--rivet-size-radius     : 50%;
	--rivet-area-size-width : 1.5rem;
	--rivet-area-size-height: 1.5rem;
	--rivet-color           : #{$color-line};
	--rivet-color-selected  : #{$color-accent};
	--rivet-shadow          : none;

	--rivet-inset           : 9999vh auto auto auto;
	@media screen and (min-width: 600px) {  // for-ta
		--rivet-inset: 0 auto auto auto;
	}
}
