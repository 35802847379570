/**
 * Style for Printing Pages
 *
 * @author Space-Time Inc.
 */

@use 'variables' as *;

@media print {
	body {
		font-size: 10.5pt !important;
	}

	.site{
		min-height: 0 !important;
	}

	.site-header {
		position : static !important;
		transform: initial !important;
	}

	.site-header + * {
		margin-top: 0 !important;
	}

	.site-header-top {
		opacity: 1 !important;
	}

	.site-title {
		max-width : 50% !important;
		max-height: #{6 * 10.5pt} !important;
	}

	.screen-only {
		display: none !important;
	}

	.has-anchor {
		padding-top: 0 !important;
		margin-top : 0 !important;
	}

	.site-footer {
		background: $color-bg !important;
	}
}
