/**
 * Mixins for User Content
 *
 * @author Space-Time Inc.
 */

@use '../../_inc/stile/sass/utility/_utility';
@use '../../_inc/stile/sass/font/_font';
@use '../../_inc/stile/sass/link/_link';
@use 'variables' as *;

@mixin content-custom {
	a:not([class]) {
		@include link.file;
	}

	@media (hover: hover) {
		a:hover {
			text-decoration: none;
		}
	}

	[data-nc-tab-bar] a {
		color          : $color-text;
		font-weight    : bold;
		text-decoration: none;
	}

	nav + nav {
		margin-block-start: var(--small-gap);
	}
}

@mixin content-custom-frame {
	div:is(.frame, .frame-alt) {
		display    : flow-root;
		clear      : both;
		padding    : 1.25rem 1.5rem;
		line-height: inherit;

		:where(:not(.alignleft, .alignright)) {
			&:first-child {
				margin-block-start: 0;
			}
			&:last-child {
				margin-block-end: 0;
			}
		}
		transition: padding 0.2s, max-height 2.0s ease, opacity 0.2s ease 0.3s;
		max-height: 9999px;
		&.hidden {
			transition   : opacity 0.2s ease, margin 0.4s 0.1s, max-height 0.4s 0.1s, padding 0.2s 0.2s;
			overflow     : hidden;
			max-height   : 0;
			padding-block: 0;
			opacity      : 0;
			margin       : 0;
		}
	}

	div.frame {
		background-color: $color-bg-1;
	}

	div:is(.frame-alt, .frame.is-style-alt) {
		background-color: $color-bg;
		border          : 1px solid $color-base;
		border-radius   : $size-border-radius;
	}

	div:is(.frame, .frame-alt, .frame.is-style-alt) + div:is(.frame, .frame-alt, .frame.is-style-alt) {
		margin-block-start: var(--small-gap);
	}
}
