/**
 * Style for Search Form and Password Form
 *
 * @author Space-Time Inc.
 */

@use '../../_inc/stile/sass/utility/_utility';
@use '../../_inc/stile/sass/font/_font';
@use '../../_inc/stile/sass/form/_button';
@use 'variables' as *;
@use 'mixins' as *;

.form,
form {
	input:focus,
	select:focus {
		outline   : none;
		box-shadow: 0 0 0 2px rgba($color-base-dark, 0.5);
	}
}


// -----------------------------------------------------------------------------


:is(.search-form, .password-form) {
	display        : inline-flex;
	flex-wrap      : wrap;
	justify-content: flex-end;
	gap            : 0.75rem;

	.label-field {
		z-index: 0;

		input { height: 100%; }
	}
	.field {
		width: bv(14rem, 18rem, 600px);

		&::placeholder {
			color: $color-base-light;
		}
	}
}


// -----------------------------------------------------------------------------


.button.search {
	min-width : 4rem;
	min-height: var(--ui-size);
	font-size : 0;

	position: relative;
	&::after {
		content  : '';
		position : absolute;
		top      : 50%;
		transform: translateY(-50%);

		width : 1.5rem;
		height: 1.5rem;

		clip-path: polygon(
			19% 19%, 12% 32%, 12% 48%, 19% 61%, 32% 68%, 48% 68%, 56% 63%,
			79% 86%, 86% 79%,
			63% 56%, 68% 48%, 68% 32%, 61% 19%, 48% 12%, 32% 12%, 19% 19%,
			27% 27%, 35% 22%, 45% 22%, 53% 27%, 58% 35%, 58% 45%, 53% 53%, 45% 58%, 35% 58%, 27% 53%, 22% 45%, 22% 35%, 27% 27%
		);
		box-shadow: 0 0 0 1rem CurrentColor inset;
	}
}


// -----------------------------------------------------------------------------


:root {
	--nc-form-font-size    : 1rem;
	--nc-form-line-height  : 1.25;
	--nc-form-border-radius: #{$size-border-radius};

	--nc-input-padding   : 0.5em 0.75em;
	--nc-input-mark-color: #{$color-base-dark};

	--nc-button-padding: 0.5rem 0.75rem 0.5rem 1.5rem;
	@include utility.for-ta {
		--nc-button-padding: 0.65rem 1.25rem 0.65rem 1.75rem;
	}
	--nc-button-border-base: #{$size-border-width} solid;

	--nc-button-background-color: #{$color-base};
	--nc-button-border-color    : #{$color-base};
	--nc-button-color           : #{$color-text-inv};
}

%button-base {
	@extend %base-button-rect;


	// -------------------------------------------------------------------------


	min-width : 13rem;
	min-height: 3rem;

	@include font.proportional;
	font-weight: bold;
	text-align : left;

	transition: all 0.2s ease;
	&::before, &::after { transition: all 0.2s ease; }
}

%button-red {
	--nc-button-background-color: #{$color-bg};
	--nc-button-border-color    : #F0664B;
	--nc-button-color           : #F0664B;

	&:hover {
		--nc-button-background-color: #fba28fc7;
		--nc-button-border-color    : #F0664B;
		--nc-button-color           : #{$color-text-inv};
	}
}

%button-accent {
	--nc-button-background-color: #{$color-fb};
	--nc-button-border-color    : #{$color-fb};
	--nc-button-color           : #{$color-text-inv};

	&:hover {
		--nc-button-background-color: #{$color-text-inv};
		--nc-button-border-color    : #{$color-fb};
		--nc-button-color           : #{$color-fb};
	}
}

%button-outline {
	--nc-button-background-color: #{$color-bg};
	--nc-button-border-color    : #{$color-base};
	--nc-button-color           : #{$color-base};

	&:hover {
		--nc-button-background-color: #{$color-base};
		--nc-button-border-color    : #{$color-base};
		--nc-button-color           : #{$color-text-inv};
	}
}

%button-angle-right {
	gap                  : 0.5rem;
	grid-template-columns: 1fr 0.5rem;

	&::after {
		content: '';
		width  : 1.5rem;
		height : 1.5rem;

		clip-path : polygon(34% 31%, 53% 50%, 34% 69%, 41% 76%, 67% 50%, 41% 24%);
		box-shadow: 0 0 0 1rem CurrentColor inset;

		margin: -0.25rem;
	}
}

%button-angle-left {
	gap                  : 0.5rem;
	grid-template-columns: 0.5rem 1fr;

	&::before {
		content: '';
		width  : 1.5rem;
		height : 1.5rem;

		clip-path : polygon(59% 24%, 33% 50%, 59% 76%, 66% 69%, 47% 50%, 66% 31%);
		box-shadow: 0 0 0 1rem CurrentColor inset;

		margin: -0.25rem;
	}
}

@mixin button-content {
	:is(a.button, .wp-block-button .wp-block-button__link) {
		@extend %button-base;
	}
	:is(a.button:not(.toggle), .wp-block-button .wp-block-button__link)                                   { @extend %button-red, %button-angle-right; }
	:is(a.button.is-style-fill:not(.toggle), .wp-block-button.is-style-fill .wp-block-button__link)       { @extend %button-red, %button-angle-right; }
	:is(a.button.is-style-accent:not(.toggle), .wp-block-button.is-style-accent .wp-block-button__link)   { @extend %button-accent, %button-angle-right; }
	:is(a.button.is-style-outline:not(.toggle), .wp-block-button.is-style-outline .wp-block-button__link) { @extend %button-outline, %button-angle-right; }
	// :is(a.button:not(.toggle).red, .wp-block-button .wp-block-button__link).red {
	// 	color: #F0664B;
	// 	&:hover {
	// 		background-color: rgba(#F0664B, 0.15);
	// 	} 
	// }   
	a.button.toggle                  { @extend %button-red; }
	a.button.is-style.fill.toggle    { @extend %button-red; }
	a.button.is-style-accent.toggle  { @extend %button-accent; }
	a.button.is-style-outline.toggle { @extend %button-outline; }

	a.button.toggle.active                  { @extend %button-outline; }
	a.button.is-style-fill.toggle.active    { @extend %button-outline; }
	a.button.is-style-accent.toggle.active  {
		@extend %button-outline;
		&:hover {
			--nc-button-background-color: #{$color-accent};
			--nc-button-border-color    : #{$color-accent};
			--nc-button-color           : #{$color-text-inv};
		}
	}
	a.button.is-style-outline.toggle.active { @extend %button-red; }
}
